import React from 'react';
import './App.css';
import Scheduler from './Scheduler';

function App() {
  return (
    <div className="App">
      <Scheduler />
    </div>
  );
}

export default App;
