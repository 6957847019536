const moment = require('moment');

export default [
  /************* TRACY ******************/
  {
    person: 'Tracy', title: 'Morning Meeting', desc: 'Group 2',
    start: moment('2020-08-13 08:25').toDate(), end: moment('2020-08-13 08:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTMzNGE3ZDctOTQxZS00NThkLWI2YTMtNDJkZjgxOTVlNTI1%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Small Group', desc: 'First Day Jitters',
    start: moment('2020-08-13 10:30').toDate(), end: moment('2020-08-13 10:55').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZjJjNDM1ZTEtMzA5OC00YjRkLTlhNDUtMjUyNzY2M2Q3Yzcz%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-13 12:00').toDate(), end: moment('2020-08-13 13:00').toDate()
  },
  {
    person: 'Tracy', title: 'Reflection Meeting', desc: 'Daily Reflection Meeting',
    start: moment('2020-08-13 13:50').toDate(), end: moment('2020-08-13 14:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZjJjNDM1ZTEtMzA5OC00YjRkLTlhNDUtMjUyNzY2M2Q3Yzcz%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Morning Meeting', desc: 'Group 2',
    start: moment('2020-08-14 08:25').toDate(), end: moment('2020-08-14 08:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NGY5NjY4ZjUtYWNmMC00NTg4LTgxYmEtY2EwZTMwNzQxMmMx%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Small Group', desc: 'Small Group B',
    start: moment('2020-08-14 10:30').toDate(), end: moment('2020-08-14 10:55').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZDQ4ZGNmZWItYjgwYi00MzIxLTljZjAtOWI0NzNhOGMzODFl%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-14 12:00').toDate(), end: moment('2020-08-14 13:00').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Morning Meeting', desc: 'Group 2',
    start: moment('2020-08-17 08:25').toDate(), end: moment('2020-08-17 08:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmFlYmRiYTctNWRkMS00YTBiLTljMzMtYzU4Mjg3NjI3NDg0%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Small Group', desc: 'Small Group',
    start: moment('2020-08-17 10:30').toDate(), end: moment('2020-08-17 10:55').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_N2NhODczNWQtMDlmMC00OWE4LWI4YmYtNDJmMmQ1ZWI3ZDM2%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-17 12:30').toDate(), end: moment('2020-08-17 13:15').toDate()
  },
  {
    person: 'Tracy', title: 'Reflection Meeting', desc: 'Reflection Meeting',
    start: moment('2020-08-17 13:50').toDate(), end: moment('2020-08-17 14:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZWMzZjMwMmYtZjFlNC00OTNhLWI5YTEtZDA3YjkyMjcyNTcw%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Morning Meeting', desc: 'Group 2',
    start: moment('2020-08-18 08:25').toDate(), end: moment('2020-08-18 08:45').toDate(),
    link: 'something'
  },
  {
    person: 'Tracy', title: 'Small Group Meeting', desc: 'Group B',
    start: moment('2020-08-18 10:30').toDate(), end: moment('2020-08-18 10:55').toDate(),
    link: 'something'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-18 12:30').toDate(), end: moment('2020-08-18 13:15').toDate()
  },
  {
    person: 'Tracy', title: 'Reflection Meeting', desc: 'Reflection Meeting',
    start: moment('2020-08-18 13:50').toDate(), end: moment('2020-08-18 14:00').toDate(),
    link: 'something'
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Morning Meeting', desc: 'Group 2',
    start: moment('2020-08-19 08:25').toDate(), end: moment('2020-08-19 08:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NDA2YTgzYWEtZDc2ZC00M2I2LWIwZDMtNzdmMTE0ZDZmM2Zj%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Group Meeting', desc: 'Group B',
    start: moment('2020-08-19 10:30').toDate(), end: moment('2020-08-19 10:55').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZmU2ZGJhZDktNjRmMi00ZmZhLWFhZTMtZjE3NmFhNDA1YWI1%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-19 12:30').toDate(), end: moment('2020-08-19 13:15').toDate()
  },
  {
    person: 'Tracy', title: 'Reflection Meeting', desc: 'Reflection Meeting',
    start: moment('2020-08-19 13:50').toDate(), end: moment('2020-08-19 14:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_MGY2YTllZmYtOTE5ZC00OGRjLWFjNmYtOGU3YzY0YjljZGY5%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Morning Meeting', desc: 'Group 2',
    start: moment('2020-08-20 08:25').toDate(), end: moment('2020-08-20 08:45').toDate(),
    link: 'something'
  },
  {
    person: 'Tracy', title: 'Small Group Meeting', desc: 'Group B',
    start: moment('2020-08-20 10:30').toDate(), end: moment('2020-08-20 10:55').toDate(),
    link: 'something'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-20 12:30').toDate(), end: moment('2020-08-20 13:15').toDate()
  },
  {
    person: 'Tracy', title: 'Reflection Meeting', desc: 'Reflection Meeting',
    start: moment('2020-08-20 13:50').toDate(), end: moment('2020-08-20 14:00').toDate(),
    link: 'something'
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Morning Meeting', desc: 'Group 2',
    start: moment('2020-08-21 08:25').toDate(), end: moment('2020-08-21 08:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NDA2YTgzYWEtZDc2ZC00M2I2LWIwZDMtNzdmMTE0ZDZmM2Zj%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Small Discussion', desc: 'Group B',
    start: moment('2020-08-21 10:30').toDate(), end: moment('2020-08-21 10:55').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NTAzNGVkZGEtMTM0NS00MDZlLTliNjAtNjVmMDVkZTFmYTlj%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-21 12:30').toDate(), end: moment('2020-08-21 13:15').toDate()
  },
  {
    person: 'Tracy', title: 'Reflection Meeting', desc: 'No Refection on Friday',
    start: moment('2020-08-21 13:50').toDate(), end: moment('2020-08-21 14:00').toDate(),
    link: 'something'
  },
  
  /************* JASON ******************/
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-13 08:15').toDate(), end: moment('2020-08-13 08:35').toDate(),
    link: 'https://teams.microsoft.com/dl/launcher/launcher.html?url=%2f_%23%2fl%2fmeetup-join%2f19%3ameeting_ZmY1NWRmMzUtNTg4OS00YTc2LWFmMWMtOWRiOWJlMDdkNGNj%40thread.v2%2f0%3fcontext%3d%257b%2522Tid%2522%253a%2522bc41c1b9-3782-4402-a9e2-bf8c71255790%2522%252c%2522Oid%2522%253a%2522bd132abf-99c5-4fd2-8679-d2ed471eb1a7%2522%257d%26anon%3dtrue&type=meetup-join&deeplinkId=9c0f8f79-765a-4cda-9fc7-b3a53b121eee&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-13 12:00').toDate(), end: moment('2020-08-13 13:00').toDate()
  },
  {
    person: 'Jason', title: 'Specials', desc: 'Closure Class Meeting',
    start: moment('2020-08-13 13:30').toDate(), end: moment('2020-08-13 14:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZWIxZDA4YzMtNmIxNi00NzU3LWEwZDgtY2Q4ODU1N2Y0NGVi%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-14 08:00').toDate(), end: moment('2020-08-14 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NGIyMzBjYjgtNWI1MS00Y2I1LTk3NzUtOTJhNjAzMDA3NDk2%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Math', desc: 'Small Group 3',
    start: moment('2020-08-14 10:00').toDate(), end: moment('2020-08-14 10:20').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_YzgyNTAzYTItYzFmZS00ZjgwLWIyZGMtZjkyNTQ2MzhmZDFj%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Science or Social Studies', desc: 'Read Aloud',
    start: moment('2020-08-14 12:00').toDate(), end: moment('2020-08-14 12:30').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_Yjk4Njg0YzctMGI5My00NjhmLTg5NzEtYTFlMWZkM2I1ODRh%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-14 12:30').toDate(), end: moment('2020-08-14 13:30').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-17 08:00').toDate(), end: moment('2020-08-17 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_YWI1OWQyZjEtOWI1OC00MjQ3LWIxODEtOGY4N2Q0ODE3MTgw%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Science or Social Studies', desc: 'Whole Group Teams Meeting',
    start: moment('2020-08-17 12:00').toDate(), end: moment('2020-08-17 12:30').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZGJhMmM2MzYtYjRjOC00ZDFlLWJkYmItYjY2ODk4ZmJkZjYy%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-17 12:30').toDate(), end: moment('2020-08-17 13:15').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-18 08:00').toDate(), end: moment('2020-08-18 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ODNhODNhZjMtM2EzYS00ZDllLWI2MTgtZTUzNGVmMGZhN2Zm%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Reading', desc: 'Individual Meeting',
    start: moment('2020-08-18 09:45').toDate(), end: moment('2020-08-18 10:05').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NGNkYjZjYzktMjRlMy00ZGIzLTk1YTctNmRmZTNhODIwNTUy%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Science or Social Studies', desc: 'Nearpod Icebreaker',
    start: moment('2020-08-18 12:00').toDate(), end: moment('2020-08-18 12:30').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_N2NlODk3MGMtYWE0MC00MDRkLWIzNmEtYTcwNDY4OTg5MGE4%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-18 12:30').toDate(), end: moment('2020-08-18 13:15').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-19 08:00').toDate(), end: moment('2020-08-19 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGQ1MzljN2YtNjZiZS00MDMyLWE4OWItYzQ0YWNjMGQ5ZjQ0%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-19 12:30').toDate(), end: moment('2020-08-19 13:15').toDate()
  },
  {
    person: 'Jason', title: 'Evaluation', desc: 'Mrs. Wilson will send out a link',
    start: moment('2020-08-19 13:30').toDate(), end: moment('2020-08-19 14:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NGFmNmFlMzYtZTM5MC00MzdjLWE3YTQtYWQ0MzA2ZDMyMDRh%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22016d1a83-25ad-4d0e-839f-a36e170cca54%22%7d'
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-20 08:00').toDate(), end: moment('2020-08-20 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZTAwMWU1MTUtNmQwMS00OWEyLWIwOWMtYjQ3Y2Q3MzY1OGVj%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Science or Social Studies', desc: 'Small Group 1',
    start: moment('2020-08-20 09:00').toDate(), end: moment('2020-08-20 10:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZDg2MWZhZmUtNDY5NS00NmYyLTgzYWItMDQyZmQwYzFjMDE3%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-20 12:30').toDate(), end: moment('2020-08-20 13:15').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-21 08:00').toDate(), end: moment('2020-08-21 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_MzE3OTVjZDYtMDQzZS00N2FiLTkyODEtYjk1NmExZTRjYTAx%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-21 12:30').toDate(), end: moment('2020-08-21 13:15').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-24 08:00').toDate(), end: moment('2020-08-24 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NzYxMTg3OTctMzhkYi00ZTk2LTg4NjYtMjNjNzNlYzBjZGQ2%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Reading', desc: 'Reading Expectations Live',
    start: moment('2020-08-24 09:00').toDate(), end: moment('2020-08-24 09:30').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NWFhNmQwMmYtMDZjZC00NTZkLTg2NzktMDdiMDU3ZTZlM2Ez%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Writing', desc: 'Writing Expectations Live',
    start: moment('2020-08-24 09:45').toDate(), end: moment('2020-08-24 10:15').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NWFhNmQwMmYtMDZjZC00NTZkLTg2NzktMDdiMDU3ZTZlM2Ez%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Math', desc: 'Math Expectations Live',
    start: moment('2020-08-24 10:45').toDate(), end: moment('2020-08-24 11:15').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NWFhNmQwMmYtMDZjZC00NTZkLTg2NzktMDdiMDU3ZTZlM2Ez%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-24 12:00').toDate(), end: moment('2020-08-24 13:00').toDate()
  },
  {
    person: 'Jason', title: 'PE', desc: 'PE',
    start: moment('2020-08-24 13:00').toDate(), end: moment('2020-08-24 13:50').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-24 08:25').toDate(), end: moment('2020-08-24 08:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NDA2YTgzYWEtZDc2ZC00M2I2LWIwZDMtNzdmMTE0ZDZmM2Zj%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-24 12:00').toDate(), end: moment('2020-08-24 13:00').toDate()
  },
  {
    person: 'Tracy', title: 'PE', desc: 'PE',
    start: moment('2020-08-24 13:00').toDate(), end: moment('2020-08-24 13:50').toDate()
  },
  {
    person: 'Tracy', title: 'Daily Reflection Meeting', desc: 'Whole Group',
    start: moment('2020-08-24 13:50').toDate(), end: moment('2020-08-24 14:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGJhMWU2ZmYtODM4YS00MWVlLWFhYWEtZGE2MWY1NmNkYzBl%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-26 08:25').toDate(), end: moment('2020-08-26 08:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NDA2YTgzYWEtZDc2ZC00M2I2LWIwZDMtNzdmMTE0ZDZmM2Zj%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Math', desc: 'Full group',
    start: moment('2020-08-26 08:45').toDate(), end: moment('2020-08-26 09:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZTBiMmVhNjctYTg3ZC00OTEzLTg3NzgtN2FhOGY5Y2ZhZmE3%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'EVALUATION', desc: 'TRACY ONLY',
    start: moment('2020-08-26 10:00').toDate(), end: moment('2020-08-26 11:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NDA2YTgzYWEtZDc2ZC00M2I2LWIwZDMtNzdmMTE0ZDZmM2Zj%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-26 12:00').toDate(), end: moment('2020-08-26 13:00').toDate()
  },
  {
    person: 'Tracy', title: 'PE', desc: 'PE',
    start: moment('2020-08-26 13:00').toDate(), end: moment('2020-08-26 13:50').toDate()
  },
  {
    person: 'Tracy', title: 'Daily Reflection Meeting', desc: 'Whole Group',
    start: moment('2020-08-26 13:50').toDate(), end: moment('2020-08-26 14:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGJhMWU2ZmYtODM4YS00MWVlLWFhYWEtZGE2MWY1NmNkYzBl%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },

  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-26 08:00').toDate(), end: moment('2020-08-26 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NzYxMTg3OTctMzhkYi00ZTk2LTg4NjYtMjNjNzNlYzBjZGQ2%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Reading', desc: 'Reading Expectations Live',
    start: moment('2020-08-26 09:00').toDate(), end: moment('2020-08-26 09:30').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NWFhNmQwMmYtMDZjZC00NTZkLTg2NzktMDdiMDU3ZTZlM2Ez%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Writing', desc: 'Writing Expectations Live',
    start: moment('2020-08-26 09:45').toDate(), end: moment('2020-08-26 10:15').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NWFhNmQwMmYtMDZjZC00NTZkLTg2NzktMDdiMDU3ZTZlM2Ez%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Math', desc: 'Math Expectations Live',
    start: moment('2020-08-26 10:45').toDate(), end: moment('2020-08-26 11:15').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NWFhNmQwMmYtMDZjZC00NTZkLTg2NzktMDdiMDU3ZTZlM2Ez%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-26 12:00').toDate(), end: moment('2020-08-26 13:00').toDate()
  },
  {
    person: 'Jason', title: 'PE', desc: 'PE',
    start: moment('2020-08-26 13:00').toDate(), end: moment('2020-08-26 13:50').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-31 08:25').toDate(), end: moment('2020-08-31 08:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NDA2YTgzYWEtZDc2ZC00M2I2LWIwZDMtNzdmMTE0ZDZmM2Zj%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Math', desc: 'Full group',
    start: moment('2020-08-31 11:15').toDate(), end: moment('2020-08-31 12:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NjY4YThjZjctNDgxMC00NjdiLTk0N2QtNGUyMDBhOWNjZmEy%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-31 12:00').toDate(), end: moment('2020-08-31 13:00').toDate()
  },
  {
    person: 'Tracy', title: 'PE', desc: 'PE',
    start: moment('2020-08-31 13:00').toDate(), end: moment('2020-08-31 13:50').toDate()
  },
  {
    person: 'Tracy', title: 'Daily Reflection Meeting', desc: 'Whole Group',
    start: moment('2020-08-31 13:50').toDate(), end: moment('2020-08-31 14:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGJhMWU2ZmYtODM4YS00MWVlLWFhYWEtZGE2MWY1NmNkYzBl%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-08-31 08:00').toDate(), end: moment('2020-08-31 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NzYxMTg3OTctMzhkYi00ZTk2LTg4NjYtMjNjNzNlYzBjZGQ2%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Reading', desc: 'Small group',
    start: moment('2020-08-31 09:00').toDate(), end: moment('2020-08-31 09:20').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGQyNDJmOGMtYjg0NC00MzQ0LWE5ZTAtZjg2MTg4N2E5NDQy%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Science', desc: 'Small group',
    start: moment('2020-08-31 09:20').toDate(), end: moment('2020-08-31 09:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NWFhNmQwMmYtMDZjZC00NTZkLTg2NzktMDdiMDU3ZTZlM2Ez%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Writing', desc: 'Writing Expectations Live',
    start: moment('2020-08-31 09:45').toDate(), end: moment('2020-08-31 10:15').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGQyNDJmOGMtYjg0NC00MzQ0LWE5ZTAtZjg2MTg4N2E5NDQy%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Math', desc: 'Math Expectations Live',
    start: moment('2020-08-31 10:45').toDate(), end: moment('2020-08-31 11:15').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGQyNDJmOGMtYjg0NC00MzQ0LWE5ZTAtZjg2MTg4N2E5NDQy%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-08-31 12:00').toDate(), end: moment('2020-08-31 13:00').toDate()
  },
  {
    person: 'Jason', title: 'PE', desc: 'PE',
    start: moment('2020-08-31 13:00').toDate(), end: moment('2020-08-31 13:50').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-09-01 08:00').toDate(), end: moment('2020-09-01 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_YjQyNTU1NzAtNTliNy00NDFkLTk3N2QtOGVhNzQ3NWM2OGFm%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Reading', desc: 'Small group',
    start: moment('2020-09-01 09:00').toDate(), end: moment('2020-09-01 09:20').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_YjMzNWEzMWItYjlmMi00YjMzLThhMDktMWZiYTAzZDc1M2Vm%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Writing', desc: 'Writing Expectations Live',
    start: moment('2020-09-01 09:45').toDate(), end: moment('2020-09-01 10:15').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_YjMzNWEzMWItYjlmMi00YjMzLThhMDktMWZiYTAzZDc1M2Vm%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Math', desc: 'Math Expectations Live',
    start: moment('2020-09-01 10:45').toDate(), end: moment('2020-09-01 11:15').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_YjMzNWEzMWItYjlmMi00YjMzLThhMDktMWZiYTAzZDc1M2Vm%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-09-01 12:00').toDate(), end: moment('2020-09-01 13:00').toDate()
  },
  {
    person: 'Jason', title: 'Specials', desc: 'Go to Art',
    start: moment('2020-09-01 13:00').toDate(), end: moment('2020-09-01 13:50').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-09-01 08:25').toDate(), end: moment('2020-09-01 08:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NDA2YTgzYWEtZDc2ZC00M2I2LWIwZDMtNzdmMTE0ZDZmM2Zj%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Math', desc: 'Full group',
    start: moment('2020-09-01 11:15').toDate(), end: moment('2020-09-01 12:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTczZGYxODktN2FlNS00YTg2LWJiZDctNjM4NDUzNmVjOGQ1%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-09-01 12:00').toDate(), end: moment('2020-09-01 13:00').toDate()
  },
  {
    person: 'Tracy', title: 'PE', desc: 'PE',
    start: moment('2020-09-01 13:00').toDate(), end: moment('2020-09-01 13:50').toDate()
  },
  {
    person: 'Tracy', title: 'Daily Reflection Meeting', desc: 'Whole Group',
    start: moment('2020-09-01 13:50').toDate(), end: moment('2020-09-01 14:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGJhMWU2ZmYtODM4YS00MWVlLWFhYWEtZGE2MWY1NmNkYzBl%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-09-02 08:25').toDate(), end: moment('2020-09-02 08:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NDA2YTgzYWEtZDc2ZC00M2I2LWIwZDMtNzdmMTE0ZDZmM2Zj%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-09-02 12:00').toDate(), end: moment('2020-09-02 13:00').toDate()
  },
  {
    person: 'Tracy', title: 'PE', desc: 'PE',
    start: moment('2020-09-02 13:00').toDate(), end: moment('2020-09-02 13:50').toDate()
  },
  {
    person: 'Tracy', title: 'Daily Reflection Meeting', desc: 'Whole Group',
    start: moment('2020-09-02 13:50').toDate(), end: moment('2020-09-02 14:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGJhMWU2ZmYtODM4YS00MWVlLWFhYWEtZGE2MWY1NmNkYzBl%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-09-02 08:00').toDate(), end: moment('2020-09-02 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_YjQyNTU1NzAtNTliNy00NDFkLTk3N2QtOGVhNzQ3NWM2OGFm%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Start reading', desc: 'Small group',
    start: moment('2020-09-02 09:00').toDate(), end: moment('2020-09-02 09:20').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZDcyZDIyMDAtYTdhNi00ODRiLWI4MjAtYzkzNTFmOWVkMzU1%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Reading', desc: 'Group #2',
    start: moment('2020-09-02 09:20').toDate(), end: moment('2020-09-02 09:40').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_Mzk1NTA2NmItN2RlNy00Zjc2LWExYWYtZjJkMDlhZDhmNzRl%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Writing', desc: 'Writing Expectations Live',
    start: moment('2020-09-02 09:45').toDate(), end: moment('2020-09-02 10:15').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZDcyZDIyMDAtYTdhNi00ODRiLWI4MjAtYzkzNTFmOWVkMzU1%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Math', desc: 'Math Expectations Live',
    start: moment('2020-09-02 10:45').toDate(), end: moment('2020-09-02 11:15').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZDcyZDIyMDAtYTdhNi00ODRiLWI4MjAtYzkzNTFmOWVkMzU1%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-09-02 12:00').toDate(), end: moment('2020-09-02 13:00').toDate()
  },
  {
    person: 'Jason', title: 'Specials', desc: 'Go to PE',
    start: moment('2020-09-02 13:00').toDate(), end: moment('2020-09-02 13:50').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-09-03 08:25').toDate(), end: moment('2020-09-03 08:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NDA2YTgzYWEtZDc2ZC00M2I2LWIwZDMtNzdmMTE0ZDZmM2Zj%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Math', desc: 'Live Lessons',
    start: moment('2020-09-03 11:10').toDate(), end: moment('2020-09-03 12:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_MzU5YjU2MDMtMjAxOC00OWExLTg5NjQtMzQ3MzE3ODBjMmI0%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-09-03 12:00').toDate(), end: moment('2020-09-03 13:00').toDate()
  },
  {
    person: 'Tracy', title: 'Specials', desc: 'Music',
    start: moment('2020-09-03 13:00').toDate(), end: moment('2020-09-03 13:50').toDate()
  },
  {
    person: 'Tracy', title: 'Daily Reflection Meeting', desc: 'Whole Group',
    start: moment('2020-09-03 13:50').toDate(), end: moment('2020-09-03 14:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGJhMWU2ZmYtODM4YS00MWVlLWFhYWEtZGE2MWY1NmNkYzBl%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-09-03 08:00').toDate(), end: moment('2020-09-03 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZmVlNzBjNWYtODhkYS00YjJiLThhMmMtNDMyMjQxZTc5NjM5%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Writing', desc: 'Writing Expectations Live',
    start: moment('2020-09-03 09:45').toDate(), end: moment('2020-09-03 10:10').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGQyNzdmNTUtM2YzMS00MGYwLWJiNTQtMmRhMDgzZTU4OGYy%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Reading', desc: 'Individual',
    start: moment('2020-09-03 10:10').toDate(), end: moment('2020-09-03 10:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGQyNzdmNTUtM2YzMS00MGYwLWJiNTQtMmRhMDgzZTU4OGYy%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Math', desc: 'Math Expectations Live',
    start: moment('2020-09-03 10:45').toDate(), end: moment('2020-09-03 11:10').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZDcyZDIyMDAtYTdhNi00ODRiLWI4MjAtYzkzNTFmOWVkMzU1%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-09-03 12:00').toDate(), end: moment('2020-09-03 13:00').toDate()
  },
  {
    person: 'Jason', title: 'Specials', desc: 'Music',
    start: moment('2020-09-03 13:00').toDate(), end: moment('2020-09-03 13:50').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Jason', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-09-08 08:00').toDate(), end: moment('2020-09-08 08:25').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZmVlNzBjNWYtODhkYS00YjJiLThhMmMtNDMyMjQxZTc5NjM5%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Writing', desc: 'Writing Expectations Live',
    start: moment('2020-09-08 09:45').toDate(), end: moment('2020-09-08 10:10').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGQyNzdmNTUtM2YzMS00MGYwLWJiNTQtMmRhMDgzZTU4OGYy%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Math', desc: 'Math Expectations Live',
    start: moment('2020-09-08 10:45').toDate(), end: moment('2020-09-08 11:10').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZDcyZDIyMDAtYTdhNi00ODRiLWI4MjAtYzkzNTFmOWVkMzU1%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%22bd132abf-99c5-4fd2-8679-d2ed471eb1a7%22%7d'
  },
  {
    person: 'Jason', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-09-08 12:00').toDate(), end: moment('2020-09-08 13:00').toDate()
  },
  {
    person: 'Jason', title: 'Specials', desc: 'Go to Art',
    start: moment('2020-09-08 13:00').toDate(), end: moment('2020-09-08 13:50').toDate()
  },
  
  ////////////////////////////////////////////////////////////////////////////////////////////
  
  {
    person: 'Tracy', title: 'Good Morning', desc: 'Full group',
    start: moment('2020-09-08 08:25').toDate(), end: moment('2020-09-08 08:45').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_MmQxZWYwMTEtYjRiZS00NzJlLThkZGItZDA4YzZiZDM4Njg1%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Math', desc: 'Live Lessons',
    start: moment('2020-09-08 11:10').toDate(), end: moment('2020-09-08 12:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_MzU5YjU2MDMtMjAxOC00OWExLTg5NjQtMzQ3MzE3ODBjMmI0%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  },
  {
    person: 'Tracy', title: 'Lunch', desc: 'Recess',
    isLunch: true,
    start: moment('2020-09-08 12:00').toDate(), end: moment('2020-09-08 13:00').toDate()
  },
  {
    person: 'Tracy', title: 'Specials', desc: 'Go to Art',
    start: moment('2020-09-08 13:00').toDate(), end: moment('2020-09-08 13:50').toDate()
  },
  {
    person: 'Tracy', title: 'Daily Reflection Meeting', desc: 'Whole Group',
    start: moment('2020-09-08 13:50').toDate(), end: moment('2020-09-08 14:00').toDate(),
    link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGJhMWU2ZmYtODM4YS00MWVlLWFhYWEtZGE2MWY1NmNkYzBl%40thread.v2/0?context=%7b%22Tid%22%3a%22bc41c1b9-3782-4402-a9e2-bf8c71255790%22%2c%22Oid%22%3a%220071a564-ce97-43b6-8639-7398fb077e26%22%7d'
  }
]
