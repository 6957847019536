import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/es.js';
import events from './events';
import './CustomScheduler.css';

var moment = require('moment-business-days');
moment.locale('en');
const localizer = momentLocalizer(moment);

function getURL(event: any) {
  if (event.link && event.link.length > 0) {
    return <React.Fragment>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      [<a style={{backgroundColor: '#F7A34E'}} href={event.link}>{event.desc}</a>]
    </React.Fragment>;
  } else if (event.isSpecial) {
    return <strong style={{backgroundColor: '#F7A34E'}} >{' - ' + event.desc}</strong>
  } else {
    return <strong>{' - ' + event.desc}</strong>
  }
}

function Event(param: any) {
  return (
    <span>
      <strong>
        {param.event.person + ' - ' + param.event.title}

        {getURL(param.event)}
      </strong>
    </span>
  );
}

const eventPropGetter = (event: any) => {
  let backgroundColor = '';
  if (event.isLunch) {
    backgroundColor = '#B8B8B8';
  }
  else if (event.person === 'Tracy') {
    if (event.link && event.link.length > 1) {
      backgroundColor = '#00897B';
    } else {
      backgroundColor = '#4DB6AC';
    }
  } else {
    if (event.link && event.link.length > 1) {
      backgroundColor = '#008AB6';
    } else {
      backgroundColor = '#5AC3DD';
    }
  }

  return {
    style: {
      backgroundColor: backgroundColor
    }
  }
};

let Scheduler = () => {
  return <Calendar
    events={events}
    min={new Date(0, 0, 0, 8, 0, 0)}
    max={new Date(0, 0, 0, 14, 15, 0)}
    step={1}
    timeslots={15}
    localizer={localizer}
    defaultView={'day'}
    defaultDate={new Date()}
    eventPropGetter={eventPropGetter}
    components={{event: Event}}
  />;
};

export default Scheduler;
